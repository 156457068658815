import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as CombinedSelectors from './combined-selectors'
import * as Player from './player'
import * as BaseGameTile from './base-game-tile'
import { Amount } from './amount'
import { mapIndexed } from './map-indexed'

export function titleSizeSmall(size) {
  switch (size) {
    case 'medium': {
      return 2
    }
    default: {
      return 2
    }
  }
}

export function titleSizeMedium(size) {
  switch (size) {
    case 'medium': {
      return 5
    }
    default: {
      return 2
    }
  }
}

function Title(props) {
  return (
    <Common.Box
      fontFamily="head"
      fontSize={[titleSizeSmall(props.size), titleSizeMedium(props.size)]}
      textTransform="capitalize"
      textAlign="center"
      mt="auto"
      zIndex="1"
    >
      {props.children}
    </Common.Box>
  )
}

Title.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

function Statistics(props) {
  const currency = Player.useCurrency()
  const i18n = I18n.useI18n()

  return (
    <React.Fragment>
      <Common.Box
        height="50%"
        position="absolute"
        left="0px"
        bottom="0px"
        right="0px"
        background="linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000)"
        borderBottomLeftRadius={1}
        borderBottomRightRadius={1}
      />
      <Common.Box
        display="flex"
        justifyContent="center"
        fontSize={[0, 1]}
        mt={1}
        mb={
          R.equals(props.backgroundOverlayImageAlignment, 'bottom') ? 1 : null
        }
        zIndex="1"
      >
        {mapIndexed((key, index) => {
          const isLastItem = R.equals(
            index + 1,
            R.length(R.keys(props.liveStatistics))
          )

          return (
            <React.Fragment key={key}>
              {key === 'minBetCents' && (
                <Amount currency={currency} decimals="show">
                  {props.liveStatistics[key]}
                </Amount>
              )}
              {key === 'seatsAvailable' &&
                props.liveStatistics[key] &&
                i18n.translate('game-tile.seats-available', {
                  seats: props.liveStatistics[key],
                })}

              {key === 'seatsAvailable' && R.not(props.liveStatistics[key]) && (
                <React.Fragment>
                  {i18n.translate('game-tile.seats-available.fallback')}
                </React.Fragment>
              )}

              {!isLastItem && <Common.Space mx={0}>{'|'}</Common.Space>}
            </React.Fragment>
          )
        }, R.keys(props.liveStatistics))}
      </Common.Box>
    </React.Fragment>
  )
}

Statistics.propTypes = {
  backgroundOverlayImageAlignment: PropTypes.string,
  liveStatistics: PropTypes.object,
}

export function LiveCasinoGameTile(props) {
  const game =
    props.game ||
    ReactRedux.useSelector(state =>
      CombinedSelectors.getGameById(state, { id: props.id })
    )

  return (
    <BaseGameTile.Loader size={props.size} title={game.title}>
      <BaseGameTile.Background
        backgroundUrl={game.backgroundUrl}
        backgroundOverlayImageAlignment={game.backgroundOverlayImageAlignment}
        backgroundOverlayUrl={game.backgroundOverlayUrl}
        disabled={props.disabled}
        size={props.size}
        data-testid={`live-casino-game-tile.${game.provider}.${game.key}`}
      >
        <Common.Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          color="g-text"
        >
          <BaseGameTile.ProviderLogo
            provider={
              R.path(['displayProvider'], game) || R.path(['provider'], game)
            }
          />
          <Title size={props.size}>{game.title}</Title>
          <Statistics
            backgroundOverlayImageAlignment={
              game.backgroundOverlayImageAlignment
            }
            liveStatistics={R.pick(['minBetCents', 'seatsAvailable'], game)}
          />
        </Common.Box>
      </BaseGameTile.Background>
    </BaseGameTile.Loader>
  )
}

LiveCasinoGameTile.propTypes = {
  disabled: PropTypes.bool,
  game: PropTypes.object,
  id: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}
