import * as React from 'react'
import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const tileAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
   opacity: 1;
   transform: scale(1);
  }
`

const Motion = styled.div`
  display: inline-grid;
  opacity: 0;
  transform: scale(0.8);

  &.animated {
    animation-name: ${tileAnimation};
    animation-duration: 0.25s;
    ${props =>
      css({
        animationDelay: `${props.delay}s`,
      })};

    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`

export function GameTileAnimation(props) {
  const [isAnimating, setIsAnimating] = React.useState(false)

  React.useEffect(() => {
    // Workaround to run animation when the tile
    // mounts, otherwise the animation runs against
    // the placeholder
    setIsAnimating(true)
  }, [])

  return (
    <Motion className={isAnimating ? 'animated' : ''} delay={props.delay}>
      {props.children}
    </Motion>
  )
}

GameTileAnimation.defaultProps = {
  delay: 0,
}

GameTileAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
}
